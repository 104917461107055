dmx.Component('leaflet-map', {

  initialData: {
    latitude: null,
    longitude: null,
    zoom: null,
  },

  attributes: {
    preferCanvas: {
      type: Boolean,
      default: false,
    },

    layerControl: {
      type: Boolean,
      default: false,
    },

    attributionControl: {
      type: Boolean,
      default: true,
    },

    zoomControl: {
      type: Boolean,
      default: true,
    },

    zoomControlPosition: {
      type: String,
      default: 'topleft',
      enum: ['topleft', 'topright', 'bottomleft', 'bottomright'],
    },

    scaleControl: {
      type: Boolean,
      default: false,
    },

    scaleControlPosition: {
      type: String,
      default: 'bottomleft',
      enum: ['topleft', 'topright', 'bottomleft', 'bottomright'],
    },

    scaleControlMetric: {
      type: Boolean,
      default: true,
    },

    scaleControlImperial: {
      type: Boolean,
      default: true,
    },

    center: {
      // https://leafletjs.com/reference.html#latlng
      // [latitude, longitude], {lat: latitude, lng: longitude}
      type: [Array, Object],
      default: undefined,
    },

    latitude: {
      type: Number,
      default: undefined,
    },

    longitude: {
      type: Number,
      default: undefined,
    },

    zoom: {
      type: Number,
      default: undefined,
    },

    keyboard: {
      type: Boolean,
      default: true,
    },

    scrollWheelZoom: {
      // true, false, 'center'
      type: [Boolean, String],
      default: true,
    },

    touchZoom: {
      // true, false, 'center'
      type: [Boolean, String],
      default: undefined,
    },

    tileProvider: {
      type: String,
      default: 'OpenStreetMap',
    },

    markers: {
      type: Array,
      default: undefined,
    },

    markerId: {
      type: String, // expression
      default: 'id',
    },

    markerLatitude: {
      type: String, // expression
      default: 'latitude',
    },

    markerLongitude: {
      type: String, // expression
      default: 'longitude',
    },

    markerTooltip: {
      type: String, // expression
      default: 'tooltip',
    },

    markerPopup: {
      type: String, // expression
      default: 'popup',
    },

    markerGroup: {
      type: String, // expression
      default: 'group',
    },

    markerDraggable: {
      type: String, // expression
      default: 'draggable',
    },

    markerIconUrl: {
      type: String, // expression
      default: 'iconUrl',
    },
  },

  methods: {
    setView (center, zoom, options) {
      this.map.setView(center, zoom, options);
    },

    setZoom (zoom, options) {
      this.map.setZoom(zoom, options);
    },

    zoomIn (delta, options) {
      this.map.zoomIn(delta, options);
    },

    zoomOut (delta, options) {
      this.map.zoomOut(delta, options);
    },

    fitBounds (bounds, options) {
      this.map.fitBounds(bounds, options);
    },

    fitWorld (options) {
      this.map.fitWorld(options);
    },

    panTo (latlng, options) {
      this.map.panTo(latlng, options);
    },

    panBy (offset, options) {
      this.map.panBy(offset, options);
    },

    flyTo (latlng, zoom, options) {
      this.map.flyTo(latlng, zoom, options);
    },

    flyToBounds (bounds, options) {
      this.map.flyToBounds(bounds, options);
    },

    setMaxBounds (bounds) {
      this.map.setMaxBounds(bounds);
    },

    setMinZoom (zoom) {
      this.map.setMinZoom(zoom);
    },

    setMaxZoom (zoom) {
      this.map.setMaxZoom(zoom);
    },

    panInsideBounds (bounds, options) {
      this.map.panInsideBounds(bounds, options);
    },

    panInside (latlng, options) {
      this.map.panInside(latlng, options);
    },

    invalidateSize () {
      this.map.invalidateSize();
    },

    stop () {
      this.map.stop();
    },

    openPopup (latitude, longitude, content, options) {
      L.popup(options).setLatLng([latitude, longitude]).setContent(content).openOn(this.map);
    },

    addMarker (options) {
      this.addMarker(options);
    },

    openMarkerPopup (id) {
      var marker = this.findMarker(id);
      if (marker) marker.openPopup();
    },

    closeMarkerPopup (id) {
      var marker = this.findMarker(id);
      if (marker) marker.closePopup();
    },

    toggleMarkerPopup (id) {
      var marker = this.findMarker(id);
      if (marker) marker.togglePopup();
    },
  },

  events: {
    zoomstart: Event,
    zoom: Event,
    zoomend: Event,
    movestart: Event,
    move: Event,
    moveend: Event,
    popupopen: Event,
    popupclose: Event,
    tooltipopen: Event,
    tooltipclose: Event,
    mapclick: Event,
    mapdblclick: Event,
    markerclick: Event,
    markerdblclick: Event,
    markermove: Event,
  },

  init () {
    this._baseLayers = null;
    this._overlays = null;
    this._markers = null;
  },

  render (node) {
    this.$parse(node);

    node.textContent = '';

    this.map = L.map(node, {
      preferCanvas: this.props.preferCanvas,
      attributionControl: this.props.attributionControl,
      zoomControl: false,
      center: this.props.center || [this.props.latitude, this.props.longitude],
      zoom: this.props.zoom,
      keyboard: this.props.keyboard,
      scrollWheelZoom: this._toBooleanOrString(this.props.scrollWheelZoom, ['center']),
      touchZoom: this._toBooleanOrString(this.props.touchZoom, ['center']),
    });

    if (node.id) {
      this.map.id = node.id;
    }

    this.baseLayers = {};
    this.overlays = {};
    this.markers = [];

    this.children.forEach(function (child) {
      if (child instanceof dmx.Component('leaflet-marker')) {
        this.markers.push(child._marker);
        this.addMarkerToMap(child._marker, child.props.group);
      }
    }, this);

    if (this.props.tileProvider == 'custom') {
      var options = {};
      if (this.props.tileMinZoom) options.minZoom = this.props.tileMinZoom;
      if (this.props.tileMaxZoom) options.maxZoom = this.props.tileMaxZoom;
      if (this.props.tileSubdomains) options.subdomains = this.props.tileSubdomains;
      if (this.props.tileAttribution) options.attribution = this.props.tileAttribution;
      L.tileLayer(this.props.tileUrl, options).addTo(this.map);
    } else {
      var tileProvider = LEAFLET_PROVIDERS.get(this.props.tileProvider) || LEAFLET_PROVIDERS.get('OpenStreetMap');
      this._tileLayer = L.tileLayer(tileProvider.urlTemplate, tileProvider).addTo(this.map);
    }

    if (this.props.layerControl) {
      this.layerControl = L.control.layers(this.baseLayers, this.overlays).addTo(this.map);
    }

    if (this.props.zoomControl) {
      this.zoomControl = L.control.zoom({ position: this.props.zoomControlPosition }).addTo(this.map);
    }

    if (this.props.scaleControl) {
      this.scaleControl = L.control.scale({
          position: this.props.scaleControlPosition,
          metric: this.props.scaleControlMetric,
          imperial: this.props.scaleControlImperial,
        }).addTo(this.map);
    }

    this.map.on('zoomlevelschange', this.onEvent.bind(this));
    this.map.on('zoomstart', this.onEvent.bind(this));
    this.map.on('zoom', this.onEvent.bind(this));
    this.map.on('zoomend', this.onEvent.bind(this));
    this.map.on('movestart', this.onEvent.bind(this));
    this.map.on('move', this.onEvent.bind(this));
    this.map.on('moveend', this.onEvent.bind(this));

    this.map.on('click', this.onMouseEvent.bind(this, 'map'));
    this.map.on('dblclick', this.onMouseEvent.bind(this, 'map'));

    this._updateData();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('center')) {
      this.map.setView(this.props.center, this.props.zoom, { animate: false });
    }

    if (updatedProps.has('latitude') || updatedProps.has('longitude')) {
      this.map.setView([this.props.latitude, this.props.longitude], this.props.zoom, { animate: false });
    }

    if (updatedProps.has('zoom')) {
      this.map.setZoom(this.props.zoom, { animate: false });
    }

    if (updatedProps.has('zoomControl')) {
      if (this.zoomControl) {
        this.zoomControl.remove();
        this.zoomControl = null;
      }

      if (this.props.zoomControl) {
        this.zoomControl = L.control.zoom({
          position: this.props.zoomControlPosition,
        }).addTo(this.map);
      }
    } else if (this.zoomControl && updatedProps.has('zoomControlPosition')) {
      this.zoomControl.setPosition(this.props.zoomControlPosition);
    }

    if (updatedProps.has('scaleControl') || updatedProps.has('scaleControlMetric') || updatedProps.has('scaleControlImperial')) {
      if (this.scaleControl) {
        this.scaleControl.remove();
        this.scaleControl = null;
      }

      if (this.props.scaleControl) {
        this.scaleControl = L.control.scale({
          position: this.props.scaleControlPosition,
          metric: this.props.scaleControlMetric,
          imperial: this.props.scaleControlImperial,
        }).addTo(this.map);
      }
    } else if (this.scaleControl && updatedProps.has('scaleControlPosition')) {
      this.scaleControl.setPosition(this.props.scaleControlPosition);
    }

    if (updatedProps.has('markers')) {
      this.markers = this.markers.filter(marker => {
        if (!marker.static) {
          marker.remove().off();
          return false;
        }

        return true;
      });

      if (Array.isArray(this.props.markers)) {
        this.props.markers.forEach(marker => {
          var scope = new dmx.DataScope(marker, this);

          this.addMarker({
            id: dmx.parse(this.props.markerId, scope),
            latitude: +dmx.parse(this.props.markerLatitude, scope),
            longitude: +dmx.parse(this.props.markerLongitude, scope),
            tooltip: dmx.parse(this.props.markerTooltip, scope),
            popup: dmx.parse(this.props.markerPopup, scope),
            draggable: !!dmx.parse(this.props.markerDraggable, scope),
            iconUrl: dmx.parse(this.props.markerIconUrl, scope),
          });
        });
      }
    }

    if (updatedProps.has('tileProvider')) {
      if (this._tileLayer) {
        this._tileLayer.remove();
        this._tileLayer = null;
      }

      var tileProvider = LEAFLET_PROVIDERS.get(this.props.tileProvider) || LEAFLET_PROVIDERS.get('OpenStreetMap');
      this._tileLayer = L.tileLayer(tileProvider.urlTemplate, tileProvider).addTo(this.map);
    }
  },

  findMarker (id) {
    return this.markers.find(function (marker) {
      return marker.id == id;
    });
  },

  addMarkerToMap (marker, group) {
    marker.remove();

    if (group) {
      this.overlays[group] = this.overlays[group] || L.layerGroup().addTo(this.map);
      marker.addTo(this.overlays[group]);
    } else {
      marker.addTo(this.map);
    }
  },

  addMarker (options) {
    var marker = L.marker([options.latitude, options.longitude], options);

    if (options.static) {
      marker.static = true;
    }

    if (options.id) {
      marker.id = options.id;
    }

    if (options.tooltip) {
      marker.bindTooltip(options.tooltip);
    }

    if (options.popup) {
      marker.bindPopup(options.popup);
    }

    if (options.iconUrl) {
      marker.setIcon({ iconUrl: options.iconUrl });
    }

    marker.on('click', this.onMouseEvent.bind(this, 'marker'));
    marker.on('dblclick', this.onMouseEvent.bind(this, 'marker'));
    marker.on('move', this.onMoveEvent.bind(this, 'marker'));

    this.markers.push(marker);

    this.addMarkerToMap(marker, options.group);

    return marker;
  },

  _updateData () {
    var center = this.map.getCenter();
    var zoom = this.map.getZoom();

    this.set({
      latitude: center.lat,
      longitude: center.lng,
      zoom: zoom,
    });
  },

  _toBooleanOrString (o, allowed) {
    if (allowed && allowed.indexOf(o) != -1) {
      return o;
    }

    return this._toBoolean(o);
  },

  _toBoolean (o) {
    return o && o != 'false' && o != '0';
  },

  onEvent (e) {
    this._updateData();
    this.dispatchEvent(e.type, null, this.data);
  },

  onMoveEvent (prefix, e) {
    this.dispatchEvent(prefix + e.type, null, {
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
      oldLatitude: e.oldLatLng.lat,
      oldLongitude: e.oldLatLng.lng,
    });
  },

  onMouseEvent (prefix, e) {
    this.dispatchEvent(prefix + e.type, null, {
      id: e.target.id,
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
      altKey: e.originalEvent.altKey,
      ctrlKey: e.originalEvent.ctrlKey,
      metaKey: e.originalEvent.metaKey,
      shiftKey: e.originalEvent.shiftKey,
      pageX: e.originalEvent.pageX,
      pageY: e.originalEvent.pageY,
      x: e.originalEvent.x || e.originalEvent.clientX,
      y: e.originalEvent.y || e.originalEvent.clientY,
    });
  },

});
